<template>
  <div class="tabs-wrapper" @click="onClick">
    <b-tabs>
      <b-tab
        v-for="(tab, i) in tabs"
        :key="i"
        :title-link-class="`w-active-border-color-${
          tab.borderColor || 'primary'
        }`"
        @click="$emit('tab-changed', tab.id)"
        :disabled="i != 0 && isLocked"
        :active="tab.id === activeTab"
      >
        <template #title>
          <icon-closed-lock-svg v-if="i != 0 && isLocked" class="icon" />
          {{ tab.title }}
          <icon-information-svg
            v-if="isTeacher && tab.tooltip"
            id="quiz-popover"
            v-b-popover.top.click.blur.hover.viewport="tab.tooltip"
            @click.prevent.stop=""
            class="icon ml-3"
          />
        </template>
      </b-tab>
    </b-tabs>
    <b-button
      v-if="isTeacher && lesson.attributes.quiz.data"
      class="create-task w-hover-color-white w-hover-background-primary"
      variant="outline-blue-button"
      @click="showCreateTaskModal"
    >
      {{ $t("Zadať ako úlohu") }}
    </b-button>
    <TaskCreateModal
      v-if="isTeacher"
      ref="create-task-modal"
      :course="lesson"
      @createTask="createTask"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import axios from "/utils/axios";
import * as Sentry from "@sentry/vue";

const api = {
  createTask: (groupId, task) => {
    return axios.postAuth("tasks", {
      data: {
        ...task,
        group: {
          connect: [{ id: groupId }],
        },
      },
    });
  },
};

export default {
  components: {
    TaskCreateModal: () => import("/components/Task/TaskCreateModal.vue"),
    "icon-information-svg": () =>
      import("/assets/icons/information.svg?inline"),
    "icon-closed-lock-svg": () => import("/assets/icons/closedLock.svg?inline"),
  },

  props: {
    tabs: {
      type: Array,
      required: true,
    },
    lesson: {
      type: Object,
      required: false,
    },
    activeTab: {
      type: String,
      required: true,
    },
  },

  computed: {
    ...mapGetters("auth", ["user", "isLoggedIn"]),
    isTeacher() {
      return this.user && this.user.type === "teacher";
    },
    isLocked() {
      return this.lesson.attributes.public && !this.isLoggedIn;
    },
  },

  methods: {
    onClick() {
      if (!this.isLoggedIn) {
        localStorage.setItem("LAST_FROM_ROUTE", window.location.pathname);
        this.EventBus.$emit("locked-content-accessed");
      }
    },
    async createTask(task) {
      if (!this.isTeacher) return;
      const groupId = task.group_id;
      delete task.group_id;

      this.$toast.clear_loading();
      try {
        const { courseId, ...data } = task;
        await api.createTask(groupId, {
          ...data,
          course: courseId,
        });

        this.$toast.success("Úloha úspešne zadaná");
      } catch (error) {
        Sentry.captureException(error);
        this.$toast.error(error);
      }
      this.$toast.stopLoading();
    },
    showCreateTaskModal() {
      if (!this.isLoggedIn) {
        return;
      }

      this.$refs["create-task-modal"].showModal();
    },
  },
};
</script>

<style lang="scss" scoped>
.tabs-wrapper {
  border-bottom: 1px solid var(--a-color-blue-border);
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-direction: row;
  row-gap: 15px;

  @media (min-width: 1440px) {
    flex-direction: row;
    row-gap: 0;
    column-gap: 15px;
  }
}

.create-task {
  margin-bottom: 0;
}

.btn {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  white-space: nowrap;

  @media (min-width: 1440px) {
    margin-bottom: 0;
  }
}

::v-deep {
  .tabs {
    max-width: 100%;
  }

  .nav-tabs {
    border: 0;
  }

  .nav-item {
    cursor: pointer;
  }
}

@media only screen and (max-width: 575px) {
  .tabs {
    order: 1;
  }

  .btn {
    margin-left: auto;
    margin-top: 1rem;
  }
}

.icon {
  width: 30px;
  height: 30px;
  outline: none;
  color: var(--a-color-primary);
}
</style>
